<template>
  <div>
    <app-datatable
      :has-add-new="false"
      show-branch-filter
      show-inventory-filter
      :headers="datatable.headers"
      :end-point="endPoint"
      :table-slots="datatable.tableSlots"
      :reset-table-data="datatable.resetTableData"
      :update-table-data="datatable.updateTableData"
      @data-loaded="handleDataLoaded($event)"
    >
      <template #pre-table>
        <v-row no-gutters>
          <v-col
            cols="auto"
            class="px-1 me-4 text-subtitle-1 font-weight-bold"
          >
            <div class="blue darken-1 px-3 rounded-sm white--text">
              {{ $t('Total Quantity') }}: {{ $_format_number(totals.quantity) }}
            </div>
          </v-col>
          <v-col
            v-if="$_can('dlshad')"
            cols="auto"
            class="px-1 text-subtitle-1 font-weight-bold"
          >
            <div class="blue darken-1 px-3 rounded-sm white--text">
              {{ $t('Total Stock Cost') }}: {{ $_format_number(totals.total_purchase_price) }} $
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-slot:quantity="slotProp">
        {{ $_format_number(slotProp.item.quantity) }}
      </template>

      <template v-slot:[`item.purchase_price`]="slotProp">
        {{ $_format_number(slotProp.item.item.purchase_price) }} $
      </template>

      <template v-slot:[`item.sale_price`]="slotProp">
        {{ $_format_number(slotProp.item.item.sale_price) }} $
      </template>

      <template v-slot:created_at="slotProp">
        <span class="d-inline-block dir-ltr">
          {{ $_format_date_time(slotProp.item.created_at) }}
        </span>
      </template>
    </app-datatable>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      deleteDialog: false,
      formData: {},
      editMode: false,
      totals: {},
      datatable: {
        resetTableData: false,
        updateTableData: false,
        headers: [
          { text: this.$t('Inventory'), value: 'inventory', sortable: false },
          { text: this.$t('Name'), value: 'item.name', sortable: false },
          { text: this.$t('Supplier'), value: 'item.supplier', sortable: false },
          { text: this.$t('Quantity'), value: 'quantity', sortable: false },
          { text: this.$t('Purchase Price'), value: 'item.purchase_price', sortable: false },
          { text: this.$t('Sale Price'), value: 'item.sale_price', sortable: false },
          { text: this.$t('Created At'), value: 'created_at', sortable: true },
        ],
        tableSlots: [
          'quantity',
          'item.purchase_price',
          'item.sale_price',
          'created_at',
        ]
      },
      endPoint: 'stock/'
    }
  },

  mounted() {
    this.$_section_title({ title: 'Stock', icon: 'mdil-home' })
  },

  methods: {
    handleDataLoaded(obj) {
      this.datatable.resetTableData = false
      this.datatable.updateTableData = false
      this.getTotals(obj.queryParams)
    },

    getTotals(queryParams) {
      axios.get(`${this.endPoint}totals/`, { params: queryParams }).then(res => {
        this.totals = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>